import React from "react"
import { Link } from 'gatsby'
import Header from "../components/header"

export default function NotFound() {
  return <>
    <Header />
    <div>Hello world!</div>
    <p>Ganda Telogo <Link to="/">Omega</Link></p>
  </>
}
